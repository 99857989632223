import React from "react"
import styled from "styled-components"
import { device } from "../util/media"

const DefaultText = styled.div`
  ${props => props.theme.fontTitle};
  color: ${props => props.theme.secondary};
  font-size: 16px;
  font-weight: bold;
`

const DashedText = styled(DefaultText)`
  padding: 10px 0;
  border: 1px solid ${props => props.theme.turkies};
  border-left-width: 0;
  border-right-width: 0;
  text-align: center;
  width: 70px;
  @media ${device.tablet} {
    width: 150px;
  }
`

const Day = styled(DefaultText)`
  font-size: 72px;
  font-weight: normal;
`

const Date = styled.div`
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 20px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default function DateTime() {
  return (
    <Wrapper>
      <DashedText>Samstag</DashedText>
      <Date>
        <DefaultText>April</DefaultText>
        <Day>23</Day>
        <DefaultText>2022</DefaultText>
      </Date>
      <DashedText>14:30</DashedText>
    </Wrapper>
  )
}
