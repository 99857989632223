import styled from "styled-components"
import { device } from "../util/media"
export const Heading = styled.div`
  color: ${props => props.theme.primary};
  ${props => props.theme.fontTitle};
  letter-spacing: 6px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  margin: 20px 0;
  margin-right: -6px;
  text-align: center;
  @media ${device.tablet} {
    font-size: 24px;
  }
`
