import React, { useState, useEffect } from "react"
import { withLayout } from "./layout"
import SEO from "./seo"
import styled, { withTheme } from "styled-components"
import HeadlineOrnament from "./headlineOrnament"
import SeparateSvg from "../../assets/separate.svg"
import MeAndSarahImageSrc from "../images/wir.jpg"

import logoSrc from "../images/logo-route.png"
import federHor from "../images/feder-auge-horizontal.png"
import federModal from "../images/feder-in-kreis.png"
import { device } from "../util/media"
import { Heading } from "./heading"
import DateTime from "./dateTime"
import Button from "./button"
import Map from "./map"
import LinkButton from "./linkButton"
import { SleepOver } from "./SleepOver"
import { GuestModal } from "./GuestModal"
import { ArtDecoNested, ArtDecoWrapper } from "./ArtDecoWrapper"
import Axios from "axios"
import { FoodVote } from "./FoodVote"
import { app, db } from "../firebase"
import HeartRaw from "../../assets/heart.svg"
import { Upload } from "./Upload"
import { Gallery } from "./gallery"

export const Separate = styled(SeparateSvg)`
  display: block;
  text-align: center;
  margin: 16px 0;
`

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OnSecondary = styled.div`
  color: #fff;
`

const Logo = styled.img.attrs({
  src: logoSrc,
})`
  margin: 15px 0;
  width: 200px;
  @media ${device.tablet} {
    width: 300px;
  }
  @media ${device.laptop} {
    width: 400px;
  }
`

const Container = styled.div`
  margin-top: 40px;
  ${props => (props.padding ? `padding: 0 20px;` : "")}
`

const Salutation = styled.div`
  font-size: 1.3em;
  text-align: center;
  margin: 20px 0 10px;
  ${props => props.theme.fontTitle};
`

export const CheckButton = styled.div`
  margin-top: 20px;
  background-color: #fff;
  color: ${props => props.theme.secondary};
  position: relative;
  overflow: hidden;
  letter-spacing: 1px;
  outline: 0;
  cursor: pointer;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

  border-radius: 50%;
  padding: 15px;

  svg {
    fill: ${props => props.theme.primary};
    display: block;
  }
`
const Timeline = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const TimelineEntry = styled(({ className, time, title }) => {
  return (
    <div className={className}>
      <div className="time">{time}</div>
      <div className="title">{title}</div>
    </div>
  )
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  .time {
    font-weight: bold;
    ${props => props.theme.fontTitle};
    color: ${props => props.theme.primary};
  }

  .title {
    margin: 10px 0 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    width: 200px;
  }
`

const AttentionContainer = styled.div`
  border: 2px solid white;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
`

const Image = styled.img`
  display: block;
  max-width: 100%;
`

const Ol = styled.ol`
  li + li {
    margin-top: 12px;
  }
`

const MeAndSarahImage = styled(AttentionContainer)`
  margin: 0 auto;
  max-width: 250px;
  @media ${device.tablet} {
    max-width: 600px;
  }
`

export const FederModalThumb = styled.img.attrs({
  src: federModal,
})`
  position: absolute;
  top: -30px;
  left: 50%;
  margin-left: -30px;
  width: 60px;
  height: 60px;
`

const Trauzeugen = styled.div`
  display: flex;
  flexdirection: row;
  .franzi {
  }
`

const IndexPage = withLayout(
  withTheme(({ theme, ...props }) => {
    const {
      pageContext: { node },
    } = props

    console.log({ node })

    const [gallery, setGallery] = useState([])
    const [loading, setLoading] = useState(true)
    const [active, setActive] = useState(false)

    const [{ commited, declined, dinner }, setCommitmentLocally] = useState({
      commited: 0,
      declined: false,
      dinner: undefined,
    })

    useEffect(() => {
      return db.doc(`guests/${node.name}`).onSnapshot(next => {
        const { declined, commited, dinner } = next.data()
        setLoading(false)
        setCommitmentLocally({
          commited,
          declined,
          dinner,
        })
      })
    }, [])

    useEffect(() => {
      return db
        .collection("images")
        .orderBy("created", "asc")
        .onSnapshot(next => {
          const images = next.docs.map(doc => ({
            ...doc.data(),
            created: doc.data().created.toDate(),
            id: doc.id,
          }))
          setGallery(images)
        })
    }, [])

    const handleImageDelete = id => {
      db.collection("images")
        .doc(id)
        .delete()
    }

    const setCommitment = ({ declined, commited }) => {
      db.doc(`guests/${node.name}`).update({ declined, commited })
    }

    const setDinner = bool =>
      db.doc(`guests/${node.name}`).update({ dinner: bool })

    const setSelectedGuests = n =>
      setCommitment({ declined: false, commited: n })
    const selectedGuests = commited

    const selected = declined || selectedGuests > 0

    let availableFoods = 0
    if (node.available === 1 && node.names.length > 1) {
      availableFoods = commited * node.names.length
    } else {
      availableFoods = commited
    }

    return (
      <>
        <SEO title="Einladung zur Hochzeit" />
        {/* {availableFoods > 0 && (
          <FoodVote
            foodModified={foodModified}
            node={node}
            commited={commited}
            availableFoods={availableFoods}
            foodVote={foodVote}
            setFoodVote={setFoodVote}
            saveFoodVote={saveFoodVote}
            selectedGuests={selectedGuests}
          />
        )} */}

        <HeadlineOrnament color={theme.primary}>
          <Heading>Wir sagten Ja</Heading>
        </HeadlineOrnament>
        <Center>
          <Logo />
          <Salutation>
            {node.salut.map(n => {
              return (
                <span key={n}>
                  {n},{` `}
                </span>
              )
            })}
          </Salutation>
          <Heading>
            Danke, dass
            {node.salut.length > 1
              ? " ihr dabei gewesen seid"
              : " du dabei gewesen bist"}
          </Heading>
          <DateTime />

          <img src={federHor} />
          {/* 
          {!selected && (
            <small>
              Zusagen bitte bis <strong>01.03.2022</strong>
            </small>
          )} */}

          {/* {loading ? (
            <p>Einen Moment bitte ...</p>
          ) : declined ? (
            <p>
              {node.names.length > 1 ? "Ihr habt" : "Du hast"} abgesagt!{" "}
              <LinkButton
                onClick={() => setCommitment({ declined: false, commited: 0 })}
              >
                Ändern
              </LinkButton>
            </p>
          ) : (
            <>
              <Button
                style={{ margin: "10px" }}
                onClick={() => {
                  if (node.available === 1) {
                    setSelectedGuests(commited > 0 ? 0 : 1)
                  } else {
                    setActive(!active)
                  }
                }}
                loading={selectedGuests > 0}
              >
                {node.names.length > 1
                  ? "Ja, wir sind dabei!"
                  : "Ja, ich bin dabei!"}
              </Button>
              {!selected && (
                <>
                  <br />
                  <small>oder</small>
                  <p>
                    <LinkButton
                      onClick={() =>
                        setCommitment({ declined: true, commited: 0 })
                      }
                    >
                      {node.names.length > 1 ? "Wir tanzen" : "Ich tanz’"} auf
                      einer anderen Hochzeit! :(
                    </LinkButton>
                  </p>
                </>
              )}

              {selectedGuests > 0 && (
                <p>
                  Wir freuen uns auf{" "}
                  {selectedGuests > 1 ||
                  (node.available > 1 && node.names.length > 1)
                    ? "Euch"
                    : "Dich"}
                  !
                </p>
              )}
            </>
          )} */}
        </Center>

        {/* <Container padding> */}
        <HeadlineOrnament color={theme.primary}>
          <Heading>Bilder hochladen</Heading>
        </HeadlineOrnament>

        <Upload guestName={node.name} />
        {/* </Container> */}

        <Center>
          <img src={federHor} />
        </Center>
        {/* <Container padding> */}
        <HeadlineOrnament color={theme.primary}>
          <Heading>Galerie</Heading>
        </HeadlineOrnament>
        <Gallery
          images={gallery}
          guestName={node.name}
          handleDelete={handleImageDelete}
        />
        {/* </Container> */}

        {/* 
        <Container padding>
          <HeadlineOrnament color={theme.primary}>
            <Heading>Gemeinsames Vorfeiern</Heading>
          </HeadlineOrnament>
          <Center>
            <p>
              Für Freitag Abend würden wir gerne mit allen Gästen, die bereits
              am Freitag anreisen oder in Wien vor Ort sind ein bisschen
              vorfeiern, denn wir haben am 22.04. unser 10 jähriges Jubiläum!
            </p>
            <p>
              Bitte gebt alle Bescheid, ob ihr bei einem gemeinsamen Abendessen
              im magdas dabei sein möchtet.
            </p>
            {!dinner && (
              <Button onClick={() => setDinner(true)}>Ja, vorfeiern!</Button>
            )}
            {dinner && (
              <>
                <p
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <HeartRaw style={{ marginRight: "12px" }} /> Yay, Du bist beim
                  gemeinsamen Vorfeiern dabei!
                </p>
                <LinkButton onClick={() => setDinner(false)}>
                  oder doch nicht?
                </LinkButton>
              </>
            )}
          </Center>
        </Container>

        <Container>
          <HeadlineOrnament color={theme.primary}>
            <Heading>Wo wird gefeiert?</Heading>
          </HeadlineOrnament>
          <p style={{ textAlign: "center" }}>
            <strong>Lusthaus Wien</strong>
            <br />
            <small>Freudenau 254, 1020 Wien</small>
          </p>
          <Map />
        </Container>

        <Container>
          <SleepOver />
        </Container>

        <Container padding>
          <HeadlineOrnament color={theme.primary}>
            <Heading>Ablauf</Heading>
          </HeadlineOrnament>

          <Ol>
            <li>
              <p>
                Für Freitag Abend würden wir gerne mit allen Gästen, die bereits
                am Freitag anreisen oder in Wien vor Ort sind ein bisschen
                vorfeiern, denn wir haben am 22.04. unser 10 jähriges Jubiläum!
              </p>
              <p>
                <strong>
                  Bitte gebt alle Bescheid, ob ihr bei einem gemeinsamen
                  Abendessen im magdas dabei sein möchtet.
                </strong>
              </p>
            </li>
            <li>
              <p>
                Für alle, die mit dem Auto am Samstag direkt zur Location
                kommen, sehen wir uns dort um 14:30 Uhr. Zahlreiche Parkplätze
                sind vorhanden.{" "}
              </p>
              <p>
                Vielleicht lohnt es sich fürs Wochenende am Prater Fahrräder zu
                leihen - dann kann man sich das Taxi in der Nacht sparen,
                solange man fahrtüchtig ist ;-)
              </p>{" "}
            </li>
          </Ol>

          <Timeline>
            {/* 
            14:30 - Treffpunkt im Lusthaus

            15:00 - Freie Trauung im 1. Stock im Lusthaus

            15:45 - Sektempfang

            18:30 - Abendessen
            
            <TimelineEntry time="14:30" title="Treffpunkt im Lusthaus" />
            <TimelineEntry time="15:00" title="Freie Trauung" />
            <TimelineEntry time="15:45" title="Sektempfang" />
            <TimelineEntry time="18:30" title="Abendessen" />
          </Timeline>

          <p>
            Die Wiener unter euch können auch gerne am Sonntag mit uns im Hotel
            frühstücken/brunchen. Für die Reservierung müssten wir hier auch
            zeitnah Bescheid wissen, ob ihr am Katerfrühstück teilnehmen
            möchtet.
          </p>
        </Container>

        <Container>
          <ArtDecoNested bg="secondary">
            <ArtDecoWrapper>
              <HeadlineOrnament color={theme.primary}>
                <Heading>Feinheiten</Heading>
              </HeadlineOrnament>
              <OnSecondary
                style={{ paddingLeft: "20px", paddingRight: "20px" }}
              >
                <h3>Motto</h3>
                <p>
                  Wir lassen uns von dem Stil der 20er Jahre inspirieren.
                  Stichworte sind da z.B. Charleston - Art Deco - Avantgarde -
                  Gatsby - Vintage ... wir freuen uns, wenn ihr Lust habt euch
                  in diesem Stil zu kleiden und mit uns die 20er am 23.04.2022
                  gebührend aufleben zu lassen! Ist aber kein Muss!{" "}
                </p>

                <h3>Geschenke</h3>
                <p>
                  Nachhaltigkeit ist uns ein wichtiges Thema... darum wünschen
                  wir uns keine wildverpackten Geschenke! Euer Kleingeld hilft
                  uns sicher bei der Planung einer kleiner Hochzeitsreise...
                </p>
                <p>
                  Wenn wir auf Feuerwerk, Wunderkerzen sowie Konfetti und Reis
                  verzichten, freut sich auch unsere Location!
                </p>
                <h3>Fahrradverleih</h3>
                <p>
                  z.B. hier:{" "}
                  <a href="http://www.radverleih-hochschaubahn.com/">
                    http://www.radverleih-hochschaubahn.com/
                  </a>
                </p>
                <h3>COVID</h3>
                <p>
                  Die Feier wird im Einklang der aktuellen Regulationen
                  abgehalten und wir bitten alle Gäste einen 2G+ Nachweis zu
                  besitzen (Geimpft/Genesen + PCR Test)
                </p>
                <p>
                  <a href="https://allesgurgelt.at/wien/tourists/">
                    Kostenloses PCR Test Angebot der Stadt Wien
                  </a>
                </p>
              </OnSecondary>
            </ArtDecoWrapper>
          </ArtDecoNested>
        </Container>

        <Container>
          <HeadlineOrnament color={theme.primary}>
            <Heading>Kontakt</Heading>
          </HeadlineOrnament>

          <MeAndSarahImage>
            <Image src={MeAndSarahImageSrc} />
          </MeAndSarahImage>

          <Container padding>
            <Center style={{ textAlign: "center" }}>
              <h3>Ihr habt Fragen?</h3>
              <p>Dann schreibt uns doch :)</p>
              <p>
                <strong>
                  Für Ideen, die wir zwei vorab nicht wissen sollten,
                </strong>{" "}
                hier der Kontakt unserer Trauzeugen:{" "}
              </p>

              <Trauzeugen>
                <div className="franzi">
                  <a href="tel:+4917631165867">Franzi Demant</a>
                </div>
                <div style={{ margin: "0 10px" }}>&</div>
                <div>
                  <a href="tel:+491708121941">Paul Kirchner</a>
                </div>
              </Trauzeugen>
            </Center>
          </Container>
        </Container> */}

        <GuestModal
          open={active}
          availablePlaces={node.available}
          setOpen={setActive}
          selectedGuests={selectedGuests}
          setSelectedGuests={setSelectedGuests}
        />
      </>
    )
  })
)

IndexPage.displayName = "GuestPage"

export default IndexPage
