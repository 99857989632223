import React from "react"
import Check from "../../assets/check.svg"
import { Heading } from "./heading"
import Select from "./select"
import Modal from "./Modal"
import { FederModalThumb, CheckButton } from "./guestTemplate"

export const GuestModal = ({
  open,
  availablePlaces,
  setOpen,
  selectedGuests,
  setSelectedGuests,
}) => {
  return (
    <Modal
      open={open && availablePlaces > 1}
      contentLabel="Gästezahl"
      setOpen={setOpen}
      overflow={"visible"}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <FederModalThumb />
        <Heading>Das freut uns!</Heading>
        <p>Zu wievielt kommt Ihr?</p>
        <Select
          value={selectedGuests}
          onChange={e => setSelectedGuests(Number(e.target.value))}
        >
          <option value="0">Bitte wählen</option>
          {Array(availablePlaces)
            .fill(true)
            .map((n, i) => {
              return (
                <option key={i} value={i + 1}>
                  {i + 1} Gäste
                </option>
              )
            })}
        </Select>
        {selectedGuests > 0 && (
          <CheckButton onClick={() => setOpen(false)}>
            <Check />
          </CheckButton>
        )}
      </div>
    </Modal>
  )
}
