import React, { useCallback, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { uploadFromBlobAsync } from "../util/storage"
import { db } from "../firebase"
import { Center, OnSecondary } from "./guestTemplate"
import { ArtDecoNested, ArtDecoWrapper } from "./ArtDecoWrapper"
import Button from "./button"
import { Rings } from "react-loader-spinner"
import styled from "styled-components"

const Ol = styled.ol`
  li + li {
    margin-top: 12px;
  }
`

// groupes an array by 5 items
const chunk = (arr, size) => {
  const chunked_arr = []
  let index = 0
  while (index < arr.length) {
    chunked_arr.push(arr.slice(index, size + index))
    index += size
  }
  return chunked_arr
}

export function Upload({ guestName }) {
  const [fileState, setFileState] = useState([])

  const colRef = db.collection("images")

  const onDrop = useCallback(async acceptedFiles => {
    acceptedFiles.forEach(file => {
      const name = file.name
      setFileState(prev => {
        return [...prev, { name, loading: true }]
      })
    })

    const chunks = chunk(acceptedFiles, 3)
    for (const chunk of chunks) {
      await Promise.all(
        chunk.map(async file => {
          const name = file.name
          try {
            const {
              downloadUrl,
              thumb,
              meta,
              width,
              height,
            } = await uploadFromBlobAsync({
              blob: file,
              name,
            })
            setFileState(prev => {
              return prev.map(item => {
                if (item.name === name) {
                  return { ...item, downloadUrl, thumb, loading: false }
                }
                return item
              })
            })

            console.log(meta)

            colRef.add({
              guestName,
              thumb,
              downloadUrl,
              created: meta?.DateTimeOriginal || meta?.CreateDate || new Date(),
              width,
              height,
            })
          } catch (e) {
            console.error(e)
            setFileState(prev => {
              return prev.map(item => {
                if (item.name === name) {
                  return { ...item, loading: false, error: e.message }
                }
                return item
              })
            })
          }
        })
      )
    }
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    accept: "image/*",
  })

  const aggregatedFileState = fileState.reduce(
    (mem, item) => {
      mem = { ...mem, totalCount: mem.totalCount + 1 }

      if (item.loading) {
        return { ...mem, loadingCount: mem.loadingCount + 1 }
      }
      if (item.error) {
        return {
          ...mem,
          errorCount: mem.errorCount + 1,
          errorItems: [...mem.errorItems, item],
        }
      }
      if (item.downloadUrl) {
        return {
          ...mem,
          successCount: mem.successCount + 1,
        }
      }
      return mem
    },
    {
      totalCount: 0,
      loadingCount: 0,
      errorCount: 0,
      successCount: 0,
      errorItems: [],
    }
  )

  useEffect(() => {
    if (aggregatedFileState.totalCount === aggregatedFileState.successCount) {
      const t = setTimeout(() => {
        setFileState([])
      }, 2000)
      return () => clearTimeout(t)
    }
  }, [aggregatedFileState.totalCount, aggregatedFileState.successCount])

  return (
    <div {...getRootProps()}>
      <ArtDecoNested bg={isDragActive ? "primary" : "secondary"}>
        <ArtDecoWrapper>
          <input {...getInputProps()} />
          <Center>
            <OnSecondary>
              <Center>
                <>
                  {isDragActive ? (
                    <p>Und loslassen :) ...</p>
                  ) : (
                    <p>Hier Bilder hineinziehen</p>
                  )}
                  <p>oder</p>
                  <p>
                    <Button>Auswählen</Button>
                  </p>
                </>
                {aggregatedFileState.totalCount > 0 && (
                  <>
                    {aggregatedFileState.loadingCount > 0 && (
                      <p>
                        <Center>
                          <Rings color="#63c39f" height={80} width={80} />
                        </Center>
                        Lade {aggregatedFileState.loadingCount}
                        {aggregatedFileState.totalCount === 1 ? (
                          " Bild hoch"
                        ) : (
                          <>
                            {` `}von {aggregatedFileState.totalCount} Bildern
                            hoch
                          </>
                        )}
                        <br />
                        Habe Geduld junger Padawan
                      </p>
                    )}
                    {aggregatedFileState.loadingCount === 0 && (
                      <p>
                        {aggregatedFileState.successCount} von{" "}
                        {aggregatedFileState.totalCount} Bildern hochgeladen
                      </p>
                    )}
                    {aggregatedFileState.loadingCount === 0 &&
                      aggregatedFileState.errorCount > 0 && (
                        <>
                          <p>Fehlerhafte Uploads</p>
                          <ul>
                            {aggregatedFileState.errorItems.map(item => (
                              <li key={item.name}>
                                {item.name} ({item.error})
                              </li>
                            ))}
                          </ul>
                        </>
                      )}
                  </>
                )}

                {fileState.length > 0 && (
                  <Ol>
                    {fileState.map(k => (
                      <li key={k.name}>
                        {k.name}
                        {k.downloadUrl && <> (hochgeladen)</>}
                        {k.loading && <> (lädt)</>}
                        {k.error && <> (Fehler: {k.error})</>}
                      </li>
                    ))}
                  </Ol>
                )}
              </Center>
            </OnSecondary>
          </Center>
        </ArtDecoWrapper>
      </ArtDecoNested>
    </div>
  )
}
