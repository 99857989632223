import React from "react"
import styled from "styled-components"
import { device } from "../util/media"
import { Heading } from "./heading"

const Route = styled.div`
  background-color: ${props => props.color || props.theme.turkies};
  width: 1em;
  height: 1em;
  transform: rotate(45deg);
  margin-top: 1px;
`

const Line = styled.div`
  border-top: 2px solid ${props => props.color || props.theme.turkies};
  width: 0;
  margin: 0.5em 0 0;
  @media ${device.mobileM} {
    width: 30px;
  }
  @media ${device.tablet} {
    width: 100px;
  }
`

const Ornament = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${props => props.size};
`

const Inner = styled.div`
  margin: 0 1rem;
  @media ${device.tablet} {
    margin: 0 3rem;
  }
`

const HeadlineOrnament = ({ color, size = ".7rem", children, className }) => {
  return (
    <div className={className}>
      <Ornament size={size}>
        <Line color={color} />
        <Route color={color} />
      </Ornament>
      <Inner>{children}</Inner>
      <Ornament size={size}>
        <Route color={color} />
        <Line color={color} />
      </Ornament>
    </div>
  )
}

export default styled(HeadlineOrnament)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${Heading} {
    max-width: 180px;
    @media ${device.tablet} {
      max-width: 320px;
    }
  }
`
