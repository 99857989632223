export const defaultVoteItems = [
  {
    id: 1,
    title: "Bio-Angusrind", // rosa gebraten mit Buttermangold und Selleriepüree
  },
  {
    id: 2,
    title: "Seesaibling", // gegrillt mit Senf-Dill, dazu Mangold
  },
  {
    id: 3,
    title: "Spargel-Gnocchi (vegetarisch)", // mit Ziegenkäse und Salbei
  },
  {
    id: 4,
    title: "Maishenderl gebacken mit Pommes für Kinder",
  },
]
