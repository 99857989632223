import React, { useState } from "react"
import { ArtDecoWrapper, ArtDecoNested } from "./ArtDecoWrapper"
import { withTheme } from "styled-components"
import HeadlineOrnament from "./headlineOrnament"
import { Heading } from "./heading"
import LinkButton from "./linkButton"
import { OnSecondary, Center, Separate } from "./guestTemplate"
import Modal from "./Modal"

export const SleepOver = withTheme(({ theme }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <ArtDecoNested bg={"secondary"}>
        <ArtDecoWrapper>
          <HeadlineOrnament color={theme.primary}>
            <Heading>Übernachtung</Heading>
          </HeadlineOrnament>
          <OnSecondary style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Center style={{ textAlign: "center" }}>
              <p>
                Wir haben im wunderbaren magdas Hotel ein Zimmerkontingent
                reserviert. Für 2 Nächte von Fr, 22.04. - So, 24.04.22. Ihr
                könnt bis spätestens 23.03.22 euer Zimmer buchen.
              </p>
              <Separate />
              <address>
                magdas HOTEL
                <br />
                Laufbergergasse 12
                <br />
                1020 Wien
              </address>
              <Separate />
              <p>
                <LinkButton
                  onClick={() => setOpen(true)}
                  style={{ display: "inline-block" }}
                >
                  Preise & Details
                </LinkButton>
              </p>
            </Center>
          </OnSecondary>
        </ArtDecoWrapper>
      </ArtDecoNested>
      <Modal
        heading="Preise & Details"
        open={open}
        setOpen={setOpen}
        contentLabel="Übernachtungsdetails"
      >
        <p>Zur Buchung bitte folgende Details beachten:</p>
        <p>
          Buchung per Mail an{" "}
          <a href="mailto:info@magdas-hotel.at">info@magdas-hotel.at</a>
          {` `}
          mit folgenden Informationen:{" "}
        </p>
        <ul>
          <li>Kennwort: Hochzeit Sarah und Luke</li>
          <li>Vor- und Nachname</li>
          <li>Buchungszeitraum</li>
          <li>Anzahl der Personen</li>
          <li>Wohnanschrift</li>
          <li>
            Nennung einer gültigen Kreditkartennummer zur Garantie
            (Kreditkartennummer, Gültigkeitsdatum, Namen des
            Kreditkarteninhabers)
          </li>
        </ul>

        <p>Frühstück gibt es bis 14 Uhr. Die Zimmerpreise sehen so aus: </p>

        <h3>Kategorie Balcony:</h3>
        <ul>
          <li>
            EUR 86,00 pro Zimmer pro Nacht exklusive Frühstück zur Einzelnutzung
          </li>
          <li>
            EUR 96,00 pro Zimmer pro Nacht exklusive Frühstück zur Doppelnutzung
          </li>
        </ul>

        <h3>Kategorie Rooftop:</h3>
        <ul>
          <li>
            EUR 96,00 pro Zimmer pro Nacht exklusive Frühstück zur Einzelnutzung
          </li>
          <li>
            EUR 106,00 pro Zimmer pro Nacht exklusive Frühstück zur
            Doppelnutzung
          </li>
        </ul>
      </Modal>
    </>
  )
})
