import { storage } from "../firebase"
import exifr from "exifr"
import imageCompression from "browser-image-compression"
import imageDim from "browser-image-size"

const getExtensionsFromFileName = fileName => {
  const parts = fileName.split(".")
  const extension = parts[parts.length - 1]
  return extension
}

const getUniqueFileName = fileName => {
  const extension = getExtensionsFromFileName(fileName)
  const name = fileName.replace(`.${extension}`, "")
  const timestamp = Date.now()
  return `${name}-${timestamp}.${extension}`
}

const getThumbnailImage = file => {
  return new Promise((resolve, reject) => {
    imageCompression(file, {
      maxWidthOrHeight: 400,
      useWebWorker: true,
    })
      .then(compressedFile => {
        resolve(compressedFile)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const uploadFromBlobAsync = async ({ blob: file, name }) => {
  if (!file || !name) return null

  const meta = await exifr.parse(file, true).catch(e => {
    console.error(e)
    return null
  })

  const orientation = await exifr.rotation(file)

  let width = meta?.ExifImageWidth || meta?.ImageWidth
  let height = meta?.ExifImageHeight || meta?.ImageHeight

  if (orientation && orientation.dimensionSwapped) {
    ;[width, height] = [height, width]
  }

  // const compressedFile = await getCompressedImage(file)
  const thumbnailFile = await getThumbnailImage(file)

  const orignalBlob = await fetch(URL.createObjectURL(file)).then(res =>
    res.blob()
  )

  const compressedThumb = await fetch(
    URL.createObjectURL(thumbnailFile)
  ).then(res => res.blob())

  const uniqueFileName = getUniqueFileName(name)
  const snapshot = await storage
    .ref()
    .child(uniqueFileName)
    .put(orignalBlob)

  const downloadUrl = await snapshot.ref.getDownloadURL()

  const thumbRef = await storage
    .ref(`${name}_200x200.${getExtensionsFromFileName(name)}`)
    .put(compressedThumb)
  const thumbDownloadUrl = await thumbRef.ref.getDownloadURL()

  if (!width || !height) {
    const imageDimensions = await imageDim(file)
    width = imageDimensions.width
    height = imageDimensions.height
  }

  return {
    downloadUrl,
    thumb: thumbDownloadUrl,
    meta,
    width,
    height,
  }
}
