import React from "react"
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps"
import styled from "styled-components"
import Json from "../mapStyle.json"

const Map = withScriptjs(
  withGoogleMap(function Map() {
    return (
      <GoogleMap
        defaultZoom={13}
        //48.192675741827536, 16.438917053881518
        defaultCenter={{ lat: 48.205562649948675, lng: 16.415845687545016 }}
        defaultOptions={{
          styles: Json,
        }}
      >
        <Marker
          label="Lusthaus"
          position={{ lat: 48.192675741827536, lng: 16.438917053881518 }}
        />
        <Marker
          label="magdas HOTEL"
          position={{
            lat: 48.213341630056874,
            lng: 16.397220429412855,
          }}
        />
      </GoogleMap>
    )
  })
)

const Container = styled.div`
  height: 300px;
  margin: 20px;
  padding: 5px;
  border: 1px solid ${props => props.theme.secondary};
`

export default () => {
  return (
    <Map
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDdPDZ5hTFnKWLSWlTsrBhTL6pqe3i5gPU&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<Container />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  )
}
