//@ts-check
import React from "react"
// import Animate from "animate.css-react"
import Modal from "react-modal"
import styled from "styled-components"
import { device } from "../util/media"
import CloseIcon from "../../assets/close.svg"
import { Heading } from "./heading"

Modal.setAppElement("#___gatsby")

const ModalWrapper = styled.div`
  color: #fff;
  position: relative;
  background: ${props => props.theme.secondary};
  textalign: center;
  overflow: ${props => props.overflow || "auto"};
  max-width: 320px;
  max-height: 70vh;
  @media ${device.tablet} {
    max-width: 340px;
  }

  @media ${device.laptop} {
    max-width: 600px;
  }
`

const ModalInner = styled.div`
  padding: 20px;
`

const Close = styled(CloseIcon)`
  fill: #fff;
  width: 16px;
  height: 16px;
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0",
    overflow: "visible",
    background: "transparent",
    transform: "translate(-50%, -50%)",
    border: 0,
    borderRadius: 0,
  },
}

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const HeadingBack = styled.div`
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default ({
  open,
  setOpen,
  contentLabel,
  children,
  heading,
  overflow = false,
}) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      contentLabel={contentLabel}
      style={customStyles}
    >
      {/* <Animate
        appear="fadeInDown"
        leave="fadeInDown"
        durationAppear={200}
        component="div"
      > */}
      <ModalWrapper className="modal-wrapper" overflow={overflow}>
        {heading && (
          <HeadingWrapper>
            <Heading style={{ paddingLeft: "20px" }}>{heading}</Heading>
            <HeadingBack>
              <Close onClick={() => setOpen(false)} />
            </HeadingBack>
          </HeadingWrapper>
        )}
        <ModalInner>{children}</ModalInner>
      </ModalWrapper>
      {/* </Animate> */}
    </Modal>
  )
}
