import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 200px;
  display: inline-block;
`

const Select = styled.select`
  width: 100%;
  height: 40px;
  padding-left: 10px;
  background: white repeat;
  color: ${props => props.theme.turkies};
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  outline: none;
  cursor: pointer;
  border: 0;
`

export default function SelectComponent(props) {
  return (
    <Wrapper>
      <Select {...props} />
    </Wrapper>
  )
}
