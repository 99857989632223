import React, { useState, useEffect } from "react"
import { ArtDecoWrapper, ArtDecoNested } from "./ArtDecoWrapper"
import styled, { withTheme } from "styled-components"
import HeadlineOrnament from "./headlineOrnament"
import { Heading } from "./heading"
import { OnSecondary, Center, Separate } from "./guestTemplate"
import { defaultVoteItems } from "./defaultVoteItems"
import { device } from "../util/media"
import Button from "./button"

const VoteList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0 auto;
  @media ${device.tablet} {
    width: 400px;
  }
`

const Counter = styled(({ count, className }) => {
  return (
    <span className={className}>
      <strong>{count}</strong>
      {` `}x
    </span>
  )
})`
  display: block;
  box-sizing: border-box;
  border-radius: 3px;
  min-width: 50px;
  padding: 10px;
  border: 1px solid white;
  margin-right: 20px;
  strong {
    line-height: 0.75em;
    font-size: 1.5em;
  }
`

const VoteItem = styled(({ className, ...props }) => {
  return (
    <li className={className} onClick={() => props.onChange()}>
      <Counter {...props} /> <span className="text">{props.text}</span>
    </li>
  )
})`
  display: flex;
  align-items: flex-start;
  margin: 16px 0 0;
  cursor: pointer;
  .text {
    display: inline-block;
  }
  padding: 16px 0 0;
  border-top: 1px dotted ${props => props.theme.primary};
  &:first-child {
    margin-top: 0;
    border-top: 0;
  }
`

export const FoodVote = withTheme(
  ({
    theme,
    node,
    voteItems = defaultVoteItems,
    foodVote = [],
    setFoodVote,
    saveFoodVote,
    foodModified,
    availableFoods,
  }) => {
    const multi = node.names.length > 1

    const salut = node.salutationNames.map(n => {
      return (
        <span key={n}>
          {n},{` `}
        </span>
      )
    })

    const foodsToChoose = availableFoods - foodVote.length

    const updateSelected = id => () => {
      if (foodsToChoose === 0) {
        const clean = foodVote.filter(i => i !== id)
        setFoodVote(clean)
      } else {
        setFoodVote([...foodVote, id])
      }
    }

    return (
      <ArtDecoNested pullTop bg={"secondary"}>
        <ArtDecoWrapper>
          <HeadlineOrnament color={theme.primary}>
            <Heading>Liebe geht durch den Magen</Heading>
          </HeadlineOrnament>
          <OnSecondary style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <Center style={{ textAlign: "center" }}>
              <p>
                Wir sind schon fleißig am Vorbereiten für die Feier unseres
                Lebens.
              </p>
              <p>
                Um {multi ? "Eure" : "Deine"} Gaumen<strong>vor</strong>freuden
                zu wecken, gibt es hier einen kleinen Einblick in das
                reichhaltige Menü, für das {multi ? "Ihr" : "Du"} nun den
                Hauptgang wählen {multi ? "dürft" : "darfst"}.
              </p>
              <p>
                Deshalb fragen wir {` `}
                {multi ? (
                  <>Euch nun, wollt Ihr ...</>
                ) : (
                  <>Dich nun, willst Du ...</>
                )}
              </p>
              <Separate />
            </Center>
            <VoteList>
              {voteItems.map(({ title, id }) => {
                return (
                  <VoteItem
                    key={id}
                    text={title}
                    count={foodVote.filter(i => i === id).length}
                    onChange={updateSelected(id)}
                  />
                )
              })}
            </VoteList>
            <Center>
              <p>
                <strong>
                  {foodsToChoose > 0 &&
                    "Bitte wähle noch " +
                      foodsToChoose +
                      " Hauptspeise" +
                      (foodsToChoose > 1 ? "n" : "")}
                </strong>
              </p>
              {foodModified === 1 ? (
                <Button onClick={saveFoodVote}>Speichern</Button>
              ) : foodModified === 2 ? (
                <strong>Gespeichert! ;-)</strong>
              ) : null}
              <Separate />
            </Center>
          </OnSecondary>
        </ArtDecoWrapper>
      </ArtDecoNested>
    )
  }
)
