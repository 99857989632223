//@ts-check
import React, { useCallback, useReducer, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"

import ButtonArrowRaw from "../../assets/button-arrow.svg"
import HeartRaw from "../../assets/heart.svg"

const ButtonArrow = styled(ButtonArrowRaw)``

const ANIMATION_MS = 600
const CIRCLE_SIZE = 50

const ripple = keyframes`
  0% {
    opacity: 0.3;
    transform: scale(1);
  }

  50% {
    transform: scale(8);
  }

  100% {
    opacity: 0;
  }
`

const Ripple = styled.div`
  position: absolute;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  height: ${CIRCLE_SIZE}px;
  width: ${CIRCLE_SIZE}px;
  background: #ddd;
  border-radius: 50%;
  opacity: 0.3;
  animation-name: ${ripple};
  animation-duration: ${ANIMATION_MS * 2}ms;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  pointer-events: none;
`

const Button = styled.div`
  background-color: #fff;
  border: 1px solid ${props => props.theme.secondary};
  border-radius: 3px;
  padding: 15px 15px;
  color: ${props => props.theme.secondary};
  ${props => props.theme.fontButton};
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  letter-spacing: 1px;
  outline: 0;
  cursor: pointer;
  text-align: center;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.2s linear;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }

  ${props =>
    props.active
      ? `
    transform: translateY(3px);
  `
      : ""}

  ${ButtonArrow}.left {
    margin-right: 10px;
  }

  ${ButtonArrow}.right {
    margin-left: 10px;
    transform: rotate(180deg);
  }

  min-width: 200px;
  &.loading {
    border-radius: 50%;
    min-width: 0;
    border-width: 0;
    text-decoration: none;
    padding: 15px;
  }
`

const Heart = styled(HeartRaw)`
  display: inherit;
`

const reducer = (ripples, { type, ...props }) => {
  return {
    ADD: () => [
      ...ripples,
      {
        ...props,
      },
    ],
    SHIFT: () => {
      ;[, ...ripples] = [...ripples]
      return ripples
    },
  }[type]()
}

let rippleId = 0

export default ({ children, loading, onClick, ...props }) => {
  const [ripples, dispatch] = useReducer(reducer, [])
  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  const myOnClick = useCallback(
    evt => {
      const box = evt.target.getBoundingClientRect()
      const id = rippleId++
      dispatch({
        type: "ADD",
        id,
        x: evt.clientX - box.left - CIRCLE_SIZE / 2,
        y: evt.clientY - box.top - CIRCLE_SIZE / 2,
      })

      onClick && onClick()

      setTimeout(() => {
        if (mountedRef.current) {
          dispatch({ type: "SHIFT" })
        }
      }, ANIMATION_MS)
    },
    [onClick]
  )

  let className = props.className || ""
  loading && (className = `${className} loading`)

  return (
    <Button
      {...props}
      className={className}
      active={ripples.length > 0}
      onClick={myOnClick}
    >
      {loading ? (
        <Heart />
      ) : (
        <>
          <ButtonArrow className="left" />
          <span className="text">{children}</span>
          <ButtonArrow className="right" />
        </>
      )}
      {ripples.map(o => (
        <Ripple x={o.x} y={o.y} key={o.id} />
      ))}
    </Button>
  )
}
