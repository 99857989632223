import React from "react"
import styled from "styled-components"

const UnstyledButton = styled.button`
  ${props => props.theme.fontButton};
  color: ${props => props.theme.turkies};
  background: transparent;
  border: 0;
  font-size: 1em;
  text-decoration: underline;
  cursor: pointer;
`

export default function LinkButton(props) {
  return <UnstyledButton {...props} />
}
