import React, { useEffect, useRef } from "react"
import { FaTrash } from "react-icons/fa"
// import styles
import "lightgallery/css/lightgallery.css"
import "lightgallery/css/lg-thumbnail.css"
import styled from "styled-components"
import "photoswipe/dist/photoswipe.css"
import { isWithinInterval } from "date-fns"
import { Gallery as ImageGallery, Item } from "react-photoswipe-gallery"
import { Center, Separate } from "./guestTemplate"

import { LazyLoadImage } from "react-lazy-load-image-component"

const GroupTitle = styled.div`
  font-size: 1.3em;
  text-align: center;
  margin: 20px 0 10px;
  ${props => props.theme.fontTitle};
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-gap: 10px;
  place-content: center;
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    cursor: pointer;
  }
`

const ItemWrapper = styled.div`
  position: relative;
  button {
    border: 0;
    background: white;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: block;
    padding: 0;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    svg {
      margin: 0;
      padding: 0;
    }
  }

  button:hover,
  button:active,
  button:focus {
    background: ${theme => theme.turkies};
  }
`

const intervals = {
  friday: {
    start: new Date(2022, 3, 22, 9, 0, 0),
    end: new Date(2022, 3, 22, 23, 59, 59),
  },
  gettingReady: {
    start: new Date(2022, 3, 23, 6),
    end: new Date(2022, 3, 23, 14, 30),
  },
  ceremony: {
    start: new Date(2022, 3, 23, 14, 30),
    end: new Date(2022, 3, 23, 16, 30),
  },
  celebration: {
    start: new Date(2022, 3, 23, 15, 30),
    end: new Date(2022, 3, 24, 18, 30),
  },
  other: {
    start: new Date(2022, 3, 24, 18, 30),
    end: new Date(2030, 0, 1),
  },
}

const ImageGrid = ({ images, handleDelete, guestName }) => (
  <Grid>
    {images.map(image => (
      <Item
        key={image.downloadUrl}
        original={image.downloadUrl}
        thumbnail={image.thumb}
        width={image.width}
        height={image.height}
      >
        {({ ref, open }) => (
          <ItemWrapper>
            {guestName === image.guestName && (
              <button type="button" onClick={() => handleDelete(image.id)}>
                <FaTrash />
              </button>
            )}
            <LazyLoadImage
              height={200}
              src={image.thumb}
              width={200}
              onClick={open}
            />
            <hidden src={image.thumb} ref={ref} style={{ display: "none" }} />
          </ItemWrapper>
        )}
      </Item>
    ))}
  </Grid>
)

export const Gallery = ({ images, guestName, handleDelete }) => {
  const instanceRef = useRef()

  useEffect(() => {
    if (instanceRef.current) {
      instanceRef.current.refresh()
    }
  }, [images])

  const grouped = images.reduce(
    (acc, image) => {
      const { created } = image
      const interval = Object.entries(intervals).find(
        ([key, { start, end }]) =>
          isWithinInterval(created, { start, end }) && key !== "other"
      )
      if (interval) {
        acc[interval[0]].push(image)
      } else {
        acc.other.push(image)
      }
      return acc
    },
    {
      friday: [],
      gettingReady: [],
      ceremony: [],
      celebration: [],
      other: [],
    }
  )

  return (
    <ImageGallery>
      {grouped.friday.length > 0 && (
        <>
          <GroupTitle>Getting together</GroupTitle>
          <ImageGrid
            images={grouped.friday}
            guestName={guestName}
            handleDelete={handleDelete}
            key="friday"
          />
        </>
      )}
      {grouped.gettingReady.length > 0 && (
        <>
          <GroupTitle>Getting ready</GroupTitle>
          <ImageGrid
            images={grouped.gettingReady}
            guestName={guestName}
            handleDelete={handleDelete}
            key="gettingReady"
          />
        </>
      )}
      {grouped.ceremony.length > 0 && (
        <>
          <GroupTitle>Ceremony</GroupTitle>
          <ImageGrid
            images={grouped.ceremony}
            guestName={guestName}
            handleDelete={handleDelete}
            key="ceremony"
          />
        </>
      )}
      {grouped.celebration.length > 0 && (
        <>
          <GroupTitle>Celebration</GroupTitle>
          <ImageGrid
            images={grouped.celebration}
            guestName={guestName}
            handleDelete={handleDelete}
            key="celebration"
          />
        </>
      )}
      {grouped.other.length > 0 && (
        <>
          <Center>
            <Separate />
          </Center>
          <ImageGrid
            images={grouped.other}
            guestName={guestName}
            handleDelete={handleDelete}
            key="other"
          />
        </>
      )}
    </ImageGallery>
    // <LightGallery
    //   elementClassNames="gallery"
    //   onInit={detail => {
    //     instanceRef.current = detail.instance
    //   }}
    //   plugins={[lgThumbnail]}
    //   speed={500}
    //   licenseKey="C0A1F487-CB5D4BDE-BBD5D73A-BE073509"
    // >
    //   {images.map(image => (
    //     <Link
    //       href={image.downloadUrl}
    //       key={image.downloadUrl}
    //       onClick={e => e.preventDefault()}
    //       className="grid-item"
    //     >
    //       <img src={image.thumb} />
    //       {/* <button
    //         onClickCapture={e => {
    //           e.preventDefault()
    //           e.stopPropagation()
    //         }}
    //         onClick={e => {
    //           e.preventDefault()
    //           e.stopPropagation()
    //         }}
    //       >
    //         <FaTrash />
    //       </button> */}
    //     </Link>
    //   ))}
    // </LightGallery>
  )
}
